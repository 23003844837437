<script>
export default {
  components: {
    Video: () => import(/* webpackChunkName: "video" */ '@/components/atoms/Video'),
    MenuButton: () => import(/* webpackChunkName: "navigation" */ '@/components/molecules/Header/MenuButton'),
  },
  computed: {
    isOpen() {
      return this.$store.state.modal.isOpen
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('modal/isOpen', false)
      this.$store.commit('overlay/isVisible', false)
    },
    onKeyup({ key }) {
      if (key === 'Escape') {
        this.closeModal()
      }
    },
  },
}
</script>

<template>
  <div class="modal" role="dialog" aria-modal="true" :class="{ ['is-open']: isOpen }">
    <MenuButton :is-open="true" role="button" @click.native="closeModal" />
    <div class="modal-video">
      <Video />
    </div>
  </div>
</template>
