<script>
export default {
  components: {
    Logo: () => import(/* webpackChunkName: "header" */ '@/components/atoms/Logo'),
    MenuButton: () => import(/* webpackChunkName: "navigation" */ '@/components/molecules/Header/MenuButton'),
  },
  data() {
    return {
      showLogo: true,
    }
  },
  computed: {
    navigation() {
      return this.$store.state.navigation
    },
    isMenuOpen() {
      return this.navigation.isOpen
    },
  },
  methods: {
    toggleNavigation() {
      this.$store.commit('navigation/toggle')
    },
  },
}
</script>

<template>
  <section class="navigation-header">
    <div class="container">
      <div class="navigation-header-container">
        <Logo class="navigation-header-logo" />
        <MenuButton
          :is-open="isMenuOpen"
          class="navigation-header-menu menu"
          @click.native.stop="toggleNavigation"
        />
      </div>
    </div>
  </section>
</template>
