<script>
export default {
  components: {
    NavigationHeader: () =>
      import(/* webpackChunkName: "navigation" */ '@/components/molecules/Navigation/NavigationHeader'),
  },
  computed: {
    isCollapsed() {
      return this.$store.state.navigation.isCollapsed
    },
  },
}
</script>

<template>
  <section class="navigation-collapse" :class="{ 'is-collapsed': isCollapsed }">
    <NavigationHeader class="navigation-collapse-header" />
  </section>
</template>
