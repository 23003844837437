<script>
import { WhatsAppMixin } from '@/components/mixins'

export default {
  components: {
    WhatsappLink: () => import(/* webpackChunkName: "whatsapp" */ '@/components/atoms/WhatsappLink'),
    EnergyLogoFooter: () => import(/* webpackChunkName: "svg" */ '@/components/atoms/svg/EnergyLogoFooter'),
  },
  mixins: [WhatsAppMixin],
  data() {
    return {
      address: '',
      contacts: [
        {
          label: 'Ligue agora',
          value: '11-3360-2167',
          link: 'tel:11-3360-2167',
          icon: () => import('@/components/atoms/svg/Phone'),
        },
        {
          label: 'E-mail',
          value: 'contato@energyportariavirtual.com.br',
          link: 'mailto:contato@energyportariavirtual.com.br',
          icon: () => import('@/components/atoms/svg/Email'),
        },
      ],
    }
  },
  computed: {
    images() {
      const images = {
        siese: {
          uid: 'logo-siese',
          url: '/images/logo-siese.png',
          alt: 'Logo Siese SP',
          title: 'Logo Siese SP',
        },
        abese: {
          uid: 'logo-abese',
          url: '/images/logo-abese.svg',
          alt: 'Logo Abese',
          title: 'Logo Abese',
        },
        ctSecurity: {
          uid: 'ct-security',
          url: '/images/ct-seguranca.png',
          alt: 'Logo CT Segurança',
          title: 'Logo CT Segurança',
        },
      }

      if (this.isMobile) {
        return [
          images.abese,
          images.siese,
          images.ctSecurity,
        ]
      }

      return [
        images.siese,
        images.abese,
        images.ctSecurity,
      ]
    },
  },
}
</script>

<template>
  <footer
    class="footer"
  >
    <div class="container">
      <div v-if="!isMobile" class="footer-header">
        <EnergyLogoFooter class="energy-logo-footer" />
      </div>

      <section class="footer-content">
        <div class="footer-content-column">
          <div class="footer-contact">
            <div
              v-for="(contact, index) in contacts"
              :key="index"
              class="footer-contact-item"
            >
              <div class="contact">
                <div class="label">
                  {{ contact.label }}
                </div>
                <div class="value">
                  <a :href="contact.link" class="link">
                    {{ contact.value }}
                  </a>
                </div>
              </div>

              <div class="icon">
                <component :is="contact.icon" class="svg" />
              </div>
            </div>

            <WhatsappLink
              :button-text="whatsAppButtonText"
              :phone="whatsAppNumber"
              :message="whatsAppMessage"
            />
          </div>
        </div>

        <div class="footer-content-column">
          <div class="footer-address">
            <div class="footer-address-item">
              <h5 class="title h5">UNIDADE ZONA NORTE | SP</h5>
              <p class="address">Av. Leôncio de Magalhães, 1493</p>
            </div>

            <div class="footer-address-item">
              <h5 class="title h5">UNIDADE ZONA OESTE E SUL | SP</h5>
              <h6 class="address h6">CENTRO DE TREINAMENTO DE SEGURANÇA</h6>
              <p class="address">Av. Eusébio Matoso, 650</p>
            </div>

            <div class="footer-address-item">
              <h5 class="title h5">UNIDADE GUARULHOS</h5>
              <p class="address">Av Salgado Filho, 2120 - Loja 15</p>
            </div>
          </div>

          <div class="footer-logos">
            <ul class="list">
              <li v-for="image in images" :key="image.uid" class="item" :class="image.uid">
                <img :src="image.url" :alt="image.alt" :title="image.title" class="img">
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>

    <!--
    <div :class="{ container: !isMobile }">
      <div class="footer-container">
        <header class="footer-header">
          <div :class="['footer-container-item', { container: isMobile }]">
            <EnergyLogoFooter v-if="!isMobile" class="energy-logo-footer" />
          </div>
        </header>
        <main class="footer-content">
          <div class="footer-content-item is-contact-info">
            <div
              v-for="(info, index) in contactInfo"
              :key="index"
              class="contact-info"
            >
              <div
                :class="[
                'contact-info-item',
                'footer-container-item',
                { container: isMobile },
              ]"
              >
                <div class="contact-info-label">
                  <span class="label">{{ info.label }}</span>
                  <span class="value">
                  <a :href="info.link" class="link">{{ info.value }}</a>
                </span>
                </div>
                <div class="contact-info-icon">
                  <component :is="info.icon" class="svg" />
                </div>
              </div>
            </div>

            <div class="container-mobile container-tablet">
              <WhatsappLink
                :button-text="whatsAppButtonText"
                :phone="whatsAppNumber"
                :message="whatsAppMessage"
              />
            </div>
          </div>

          <div class="footer-content-item">
            <div class="container-mobile container-tablet">
              <div class="footer-address-container">
                <div class="footer-address">
                  <h5 class="title">UNIDADE ZONA NORTE | SP</h5>
                  <p class="address">Av. Leôncio de Magalhães, 1493</p>
                </div>

                <div class="footer-address">
                  <h5 class="title">UNIDADE ZONA OESTE E SUL | SP</h5>
                  <h6 class="address">CENTRO DE TREINAMENTO DE SEGURANÇA</h6>
                  <p class="address">Av. Eusébio Matoso, 650</p>
                </div>

                <div class="footer-address">
                  <h5 class="title">UNIDADE GUARULHOS</h5>
                  <p class="address">Av Salgado Filho, 2120 - Loja 15</p>
                </div>
              </div>

              <div class="footer-logos">
                <ul class="list">
                  <li v-for="image in images" :key="image.uid" class="item" :class="image.uid">
                    <img :src="image.url" :alt="image.alt" :title="image.title" class="img">
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    -->
  </footer>
</template>
