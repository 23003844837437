<script>
export default {
  components: {
    EnergyLogo: () => import(/* webpackChunkName: "logo" */ '@/components/atoms/svg/EnergyLogo'),
  },
}
</script>

<template>
  <section class="logo">
    <EnergyLogo class="svg-logo" />
  </section>
</template>
