<script>
export default {
  components: {
    SaoPauloCityIllustration: () =>
      import(/* webpackChunkName: "svg" */ '@/components/atoms/svg/SaoPauloCity'),
    SurveillanceIllustration: () =>
      import(/* webpackChunkName: "svg" */ '@/components/atoms/svg/Surveillance'),
  },
  methods: {
    onBecomeVisible(elementProps) {
      console.log('onBecomeVisible', elementProps)
    },
  },
}
</script>

<template>
  <section class="credibility">
    <div class="container">
      <header class="credibility-header">
        <div class="credibility-title">
          <span class="credibility-label" v-text="'Credibilidade'" />
        </div>
        <SaoPauloCityIllustration class="sao-paulo-city-illustration svg" />
      </header>
      <main class="credibility-content">
        <div class="credibility-description-wrapper">
          <p class="credibility-description">
            A Energy é autorizada Tecvoz e integra a vigilância solidária em áreas
            públicas através do programa City Câmeras, que usa tecnologia de ponta
            por uma São Paulo mais segura. O programa é uma importante ferramenta
            do poder público para prevenir e reagir à situações de emergência,
            ocorrências e manutenção do espaço público.
          </p>
          <a
            v-if="isTablet"
            href="https://vigilanciasolidaria.online"
            target="_blank"
            rel="noopener"
            class="credibility-link link-hover link"
            v-text="'Mais sobre a Vigilância Solidária'"
          />
        </div>

        <SurveillanceIllustration v-if="isTablet" class="credibility-illustration svg" />
      </main>
      <footer v-if="isMobile" class="credibility-footer">
        <div class="credibility-link-wrapper">
          <a
            href="https://vigilanciasolidaria.online"
            target="_blank"
            rel="noopener"
            class="credibility-link link-hover link"
            v-text="'Mais sobre a Vigilância Solidária'"
          />
        </div>
        <div class="credibility-illustration-wrapper">
          <SurveillanceIllustration class="credibility-illustration svg" />
        </div>
      </footer>
    </div>
  </section>
</template>
