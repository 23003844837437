<script>
import { responsive, cssMenuClass } from '@/models'
import { isFullscreen } from '@/helpers'

const isDev = process.env.NODE_ENV === 'development'

export default {
  components: {
    MainHeader: () => import(/* webpackChunkName: "header" */ '@/components/molecules/header/MainHeader'),
    Introduction: () => import(/* webpackChunkName: "fragments" */ '@/components/molecules/Introduction'),
    Chart: () => import(/* webpackChunkName: "fragments" */'@/components/molecules/Chart'),
    Showcase: () => import(/* webpackChunkName: "fragments" */'@/components/molecules/Showcase'),
    Feature: () => import(/* webpackChunkName: "fragments" */'@/components/molecules/Feature'),
    Credibility: () => import(/* webpackChunkName: "fragments" */'@/components/molecules/Credibility'),
    Footer: () => import(/* webpackChunkName: "footer" */'@/components/molecules/Footer'),
    Overlay: () => import(/* webpackChunkName: "modal" */ '@/components/atoms/Overlay'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/atoms/Modal'),
    NavigationModal: () =>
      import(/* webpackChunkName: "navigation" */ '@/components/molecules/Navigation/NavigationModal'),
    NavigationCollapse: () =>
      import(/* webpackChunkName: "navigation" */'@/components/molecules/Navigation/NavigationCollapse'),
  },
  data() {
    return {
      lastScrollTop: global.pageYOffset,
      isVideoOpen: false,
    }
  },
  computed: {
    navigation() {
      return this.$store.navigation
    },
  },
  mounted() {
    document.firstElementChild.classList.remove(cssMenuClass.isOpen)
    // this.$store.commit('navigation/isOpen', true)

    if (isDev) {
      console.log(
        'responsive.is:',
        responsive.is(),
        'exact:',
        responsive.is(responsive.is(), true)
      )
    }
  },
  methods: {
    onKeyup(event) {
      if (event.key === 'Escape') {
        this.$store.commit('navigation/isOpen', false)
        this.handleVideo(false)
      }
    },
    onScroll(event) {
      if (!this.$store.state.navigation.isOpen) {
        this.navigationHandler()
      }
    },
    onChangeFullscreen(event) {
      if (!isFullscreen()) {
        this.handleVideo(false)
      }
    },
    navigationHandler() {
      const scrollTop = global.pageYOffset || document.documentElement.scrollTop

      if (!this.$refs && !this.$refs.introduction) return

      if (this.$refs.introduction.$el.getBoundingClientRect().top >= 0) {
        this.$store.commit('navigation/isCollapsed', true)
      } else {
        this.$store.commit(
          'navigation/isCollapsed',
          scrollTop < this.lastScrollTop
        )
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    },
    hideMenu() {
      this.$store.commit('navigation/isOpen', false)
    },
  },
}
</script>

<template>
  <section class="main-wrapper">
    <NavigationCollapse />
    <MainHeader ref="mainHeader" />
    <Introduction ref="introduction" />
    <Chart ref="chart" />
    <Showcase ref="showcase" />
    <Feature ref="feature" />
    <Credibility ref="credibility" />
    <Footer ref="footer" />
    <NavigationModal />
    <Modal />
    <Overlay />
  </section>
</template>
