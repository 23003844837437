<script>
import Swiper from 'swiper'

import(/* webpackChunkName:'swiper' */ 'swiper/dist/css/swiper.css')

export default {
  data() {
    return {
      active: 4,
      slides: [
        {
          title: 'Seguro',
          content:
            'Aumento significativo da segurança, já que o porteiro não fica exposto à uma situação de risco ou de rendição.',
        },
        {
          title: 'Controle',
          content:
            'Cada acesso dos funcionários, visitantes e moradores ao condomínio é registrado com a data, horário e vídeos. ',
        },
        {
          title: 'Eficiente',
          content:
            'Em situação de risco, o morador aciona o botão pânico silencioso e agimos monitorando e acionando a polícia.',
        },
        {
          title: 'Economia',
          content:
            'Redução dos altos custos com a mão de obra de uma portaria tradicional e livre do pagamento de férias e ações trabalhistas.',
        },
      ],
    }
  },
  mounted() {
    if (this.isMobile || this.isTabletOnly) {
      this.initSlider()
    }
  },
  methods: {
    initSlider() {
      const options = {
        slidesPerView: this.isTabletOnly ? 2.25 : 1.25,
        centeredSlides: !this.isTabletOnly,
        spaceBetween: 6,
        grabCursor: true,
        pagination: {
          el: this.$refs.pagination,
          clickable: true,
        },
      }
      return new Swiper(this.$refs.slider, options)
    },
  },
}
</script>

<template>
  <section class="feature">
    <div class="container">
      <header class="feature-header">
        <h4 class="feature-header-title" v-text="'Vantagens'" />
      </header>
    </div>

    <div v-if="!isDesktop" ref="slider" :class="[{ container: isTabletOnly }]">
      <div class="feature-slides swiper-wrapper">
        <div v-for="(slide, index) in slides" :key="index" class="slide swiper-slide">
          <div class="feature-slide">
            <header class="feature-slide-header">
              <span class="feature-slide-label" v-text="'Mais'" />
              <h2 class="feature-slide-title" v-text="slide.title" />
            </header>
            <main class="feature-slide-content">
              <p class="feature-slide-description" v-text="slide.content" />
            </main>
          </div>
        </div>
      </div>
      <div class="feature-slide-controls">
        <div ref="pagination" class="swiper-pagination" />
      </div>
    </div>

    <section v-else class="feature-slides container">
      <div v-for="(slide, index) in slides" :key="index" class="slide">
        <div class="feature-slide">
          <header class="feature-slide-header">
            <span class="feature-slide-label" v-text="'Mais'" />
            <h2 class="feature-slide-title" v-text="slide.title" />
          </header>
          <main class="feature-slide-content">
            <p class="feature-slide-description" v-text="slide.content" />
          </main>
        </div>
      </div>
    </section>
  </section>
</template>
