<script>
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    NavigationHeader: () =>
      import(/* webpackChunkName: "navigation" */ '@/components/molecules/Navigation/NavigationHeader'),
  },
  computed: {
    navigation() {
      return this.$store.state.navigation
    },
  },
  methods: {
    onClickOutside() {
      if (!this.navigation.isAnimationInProgress) {
        this.hideNavigation()
      }
    },
    onGoToItem() {
      this.hideNavigation()
    },
    hideNavigation() {
      this.$store.commit('navigation/isOpen', false)
    },
  },
}
</script>

<template>
  <transition name="menu-transition">
    <section
      v-show="navigation.isOpen"
      ref="modal"
      v-click-outside="onClickOutside"
      class="navigation-modal"
      :class="{'is-open': navigation.isOpen}"
      @keydown.esc="hideNavigation"
    >
      <NavigationHeader class="navigation-modal-header" />

      <main class="navigation-modal-content">
        <nav class="nav">
          <div class="container">
            <ul class="list" tabindex="1" role="menu">
              <li
                v-for="(item, index) in navigation.items"
                :key="`item-${index}`"
                :class="['item', { 'is-visible': item.isVisible }]"
                role="menuitem"
              >
                <a
                  v-scroll-to="item.to"
                  href="#"
                  class="link"
                  role="link"
                  @click="onGoToItem"
                  v-text="item.label"
                />
              </li>
            </ul>
          </div>
        </nav>
      </main>
    </section>
  </transition>
</template>
