<script>
export default {
  props: {
    isOpen: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menuBarItems: ['menu-bar-top', 'menu-bar-middle', 'menu-bar-bottom'],
    }
  },
}
</script>

<template>
  <button
    class="menu-button"
    aria-label="Abrir menu de navegação"
    :class="{ ['is-open']: isOpen }"
    type="button"
  >
    <span class="menu">
      <span
        v-for="(cssClass, index) in menuBarItems"
        :key="index"
        :class="['menu-bar', { [cssClass]: cssClass }]"
      />
    </span>
  </button>
</template>
