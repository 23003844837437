<script>
export default {
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ '@/components/atoms/svg/Loading'),
  },
  data() {
    return {
      isLoading: true,
      player: undefined,
      medias: [
        {
          src: '/media/energy.mp4',
          type: 'video/mp4',
        },
        {
          src: '/media/energy.webm',
          type: 'video/webm; codecs=vp9,vorbis',
        },
      ],
      config: {
        preload: 'auto',
        loop: 'loop',
        autoplay: false,
      },
    }
  },
  computed: {
    isModalOpen() {
      return this.$store.state.modal.isOpen
    },
  },
  watch: {
    isModalOpen(isOpen) {
      const video = this.$refs.video.querySelector('video')

      video.currentTime = 0

      if (isOpen) {
        video.play()
      } else {
        video.pause()
      }
    },
  },
  mounted() {
    const video = this.$refs.video.querySelector('video')

    video.oncanplaythrough = () => {
      this.isLoading = false
    }
  },
}
</script>

<template>
  <section ref="video" class="video" :class="{ 'is-loading': isLoading }">
    <Loading v-if="isLoading" class="loading-icon" />
    <video
      :preload="config.preload"
      :loop="config.loop"
      :muted="config.muted"
      :autoplay="config.autoplay"
      controls
    >
      <source v-for="(media, index) in medias" :key="index" :src="media.src" :type="media.type">
    </video>
  </section>
</template>
