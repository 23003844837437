<script>
export default {
  computed: {
    isVisible() {
      return this.$store.state.overlay.isVisible
    },
  },
  methods: {
    onClose() {
      this.$store.commit('navigation/isOpen', false)
      this.$store.commit('modal/isOpen', false)
      this.$store.commit('overlay/isVisible', false)
    },
  },
}
</script>

<template>
  <section :class="['overlay', { 'is-hidden': !isVisible }]" @click="onClose" />
</template>
