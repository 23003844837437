<script>
import { WhatsAppMixin } from '@/components/mixins'

export default {
  mixins: [WhatsAppMixin],
}
</script>

<template>
  <section class="introduction">
    <div class="container">
      <header ref="header" class="introduction-header">
        <h3 class="introduction-title first">
          A portaria que você
          <br>
          já conhece.
        </h3>
        <h3 class="introduction-title second orange">
          Só que diferente ;)
        </h3>
      </header>

      <main ref="content" class="introduction-content">
        <p class="paragraph">
          Ao invés de porteiros presenciais, a Energy monitora cada entrada e
          saída no condomínio pela nossa Central 24H com câmeras de alta
          resolução. Essa tecnologia garante um aumento significativo na
          segurança, eliminando o risco de exposição do porteiro, além de
          relatórios diponíveis com hora, data e imagens dos acessos. Aproveite
          os benefícios de um novo tempo com mais segurança e economia para
          todos.
        </p>
      </main>

      <footer ref="footer" class="introduction-footer">
        <WhatsappLink
          :button-text="whatsAppButtonText"
          :phone="whatsAppNumber"
          :message="whatsAppMessage"
        />
      </footer>
    </div>
  </section>
</template>
