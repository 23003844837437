<script>
export default {
  components: {
    RadialChart: () => import(/* webpackChunkName: "svg" */ '@/components/atoms/svg/RadialChart'),
  },
  data() {
    return {
      progress: 0,
      percent: 0,
      isVisible: false,
      isActive: false,
    }
  },
  methods: {
    handleChart(event) {
      if (event.percentInView > 0.3 && !this.isActive) {
        this.isActive = true

        setTimeout(() => {
          this.isVisible = true
        }, 100)

        setTimeout(() => {
          const totalProgress = 60
          this.progress = totalProgress

          Array.from(
            Array(totalProgress),
            (x, index) => totalProgress - index
          ).forEach(item => {
            setTimeout(() => {
              this.percent = item
            }, item * 15)
          })
        }, 400)
      }
    },
  },
}
</script>

<template>
  <section :class="['chart', { 'is-visible': true }]">
    <div class="container">
      <div class="radial-chart-wrapper">
        <RadialChart v-view="handleChart" :progress="progress" :percent="percent" />
      </div>

      <h3 class="chart-title">
        dos custos com portaria
        <br>
        e aumento de segurança
      </h3>
    </div>
  </section>
</template>
