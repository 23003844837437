<script>
export default {
  data() {
    return {
      cards: [
        {
          key: 'guests',
          title: 'Visitantes',
          isVisible: false,
          illustration: () => import('@/components/atoms/svg/Guest'),
          content: [
            'O visitante toca o interfone e a Central Energy 24H identifica e visualiza a pessoa pelo circuito de câmeras.',
            'O atendentente consulta o apartamento para autorizar a entrada do visitante. Ao entrar no condomínio, ele é monitorado e as imagens são sempre gravadas.',
          ],
        },
        {
          key: 'residents',
          title: 'Moradores',
          isVisible: false,
          illustration: () => import('@/components/atoms/svg/Residents'),
          content: [
            'A entrada e saída de cada morador são feitas por biometria, senha ou tag de acesso e ficam registradas na base de dados.',
            'Cada morador ainda tem um controle com sistema anticlonagem para acessar a garagem de forma simples, rápida e muito segura.',
          ],
        },
        {
          key: 'mailman',
          title: 'Entregas',
          isVisible: false,
          illustration: () => import('@/components/atoms/svg/MailMan'),
          content: [
            'O zelador ou qualquer outro funcionário  é treinado para receber as encomendas e correspondências.',
            'Ele é acionado pela Central Energy por um ramal de interfone virtual instalado em seu próprio celular. Todo o processo é gravado e disponível ao síndico.',
          ],
        },
        {
          key: 'emergency',
          title: 'Emergências',
          isVisible: false,
          illustration: () => import('@/components/atoms/svg/Lamp'),
          content: [
            'Em caso de falta de energia, a portaria continua funcionando normalmente através do uso de No-Breaks.',
            'Em uma pane elétrica ou em caso de emergência, a Energy disponibiliza porteiros presenciais para o condomínio pelo tempo que for necessário.',
          ],
        },
      ],
    }
  },
}
</script>

<template>
  <section class="showcase">
    <div class="container">
      <header class="showcase-header">
        <h4 class="showcase-header-title">
          Veja como funciona:
        </h4>
      </header>

      <main class="showcase-cards">
        <div
          v-for="(card, index) in cards"
          :key="index"
          :class="['showcase-card', card.key]"
        >
          <div class="showcase-card-illustration">
            <component
              :is="card.illustration"
              :class="['svg', `${card.key}-illustration`]"
            />
          </div>
          <div class="showcase-card-content">
            <h2 class="showcase-card-title">
              {{ card.title }}
            </h2>
            <p
              v-for="(paragraph, paragraphIndex) in card.content"
              :key="paragraphIndex"
              class="paragraph"
            >
              {{ paragraph }}
            </p>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>
