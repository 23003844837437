<script>
export default {
  components: {
    WhatsAppIllustration: () => import(/* webpackChunkName: "whatsapp" */ '@/components/atoms/svg/WhatsApp'),
  },
  props: {
    phone: {
      required: false,
      default: '',
      type: String,
    },
    message: {
      required: false,
      default: '',
      type: String,
    },
    buttonText: {
      required: false,
      default: 'Fale com a gente no WhatsApp',
      type: String,
    },
  },
  computed: {
    whatsappLink() {
      const phone = this.phone.replace(/\D/g, '')
      return `https://wa.me/${phone}?text=${encodeURIComponent(this.message)}`
    },
  },
}
</script>

<template>
  <a :href="whatsappLink" target="_blank" rel="noopener" class="whatsapp-link link">
    <span>{{ buttonText }}</span>
    <WhatsAppIllustration class="whatsapp-illustration" />
  </a>
</template>
