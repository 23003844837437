<script>
export default {
  data() {
    return {
      strokeDashoffset: 0,
    }
  },
  props: {
    progress: {
      type: Number,
      default: 0,
      required: false,
    },
    percent: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler() {
        const max = -219.99078369140625
        this.strokeDashoffset = (1 + (100 + this.progress) / 100) * max
      },
    },
  },
}
</script>

<template>
  <svg
    class="radial-chart noselect"
    :data-progress="progress"
    x="0px"
    y="0px"
    viewBox="0 0 80 80"
  >
    <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
    <path
      ref="fill"
      stroke-linecap="round"
      :style="[{ 'stroke-dashoffset': strokeDashoffset }]"
      class="fill"
      d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
    />
    <text
      class="description"
      x="50%"
      y="32%"
      font-size="7"
      v-text="'Redução de até'"
    />

    <text class="value" x="50%" y="75%" font-size="45" v-text="percent" />
    <text class="percent" x="80%" y="55%" font-size="9" v-text="'%'" />
  </svg>
</template>
