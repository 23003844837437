<script>
export default {
  components: {
    PlayIllustration: () => import(/* webpackChunkName: "svg" */ '@/components/atoms/svg/Play'),
    NavigationHeader: () =>
      import(/* webpackChunkName: "navigation" */ '@/components/molecules/Navigation/NavigationHeader'),
  },
  computed: {
    isOpen() {
      return this.$store.state.navigation.isOpen
    },
  },
  methods: {
    openVideo() {
      this.$store.commit('modal/isOpen', true)
      this.$store.commit('navigation/isOpen', false)
      this.$store.commit('overlay/isVisible', true)
    },
    removeBackground() {
      document.firstElementChild.classList.add('video-was-loaded')
    },
    toggleNavigation() {
      this.$store.commit('navigation/toggle')
    },
  },
}
</script>

<template>
  <header class="main-header">
    <NavigationHeader />

    <div class="container">
      <div class="main-header-content">
        <div class="main-header-bottom">
          <h1 ref="title" class="main-header-title h4" v-text="'Energy Portaria Virtual'" />
          <h2 ref="description" class="main-header-description h1">
            <span class="main-header-description-top">Um novo</span>
            <span class="main-header-description-middle">conceito em</span>
            <span class="main-header-description-bottom">portarias.</span>
          </h2>
        </div>
      </div>
      <div class="main-header-footer">
        <button type="button" role="button" class="open-video" @click.stop="openVideo">
          <PlayIllustration class="play-illustration svg" />
          <span class="text" v-text="'Dê um play e conheça a Energy ;)'" />
        </button>
      </div>
    </div>
    <video
      class="main-header-video"
      preload="none"
      loop
      muted
      autoplay
      :onload="removeBackground()"
    >
      <source src="/media/header-placeholder.webm" type="video/webm; codecs=vp9,vorbis">
      <source src="/media/header-placeholder.mp4" type="video/mp4">
    </video>
  </header>
</template>
